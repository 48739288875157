/**
 * Notation Colleges API
 *  A RESTful API for Notation Colleges app
 *
 * OpenAPI spec version: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserDTO { 
    firstname: string;
    lastname: string;
    email: string;
    id_ext: string;
    phone: string;
    password: string;
    rights: Array<string>;
    ext: boolean;
    juryId: string;
    schoolId: string;
    active: boolean;
}