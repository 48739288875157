/**
 * Notation Colleges API
 *  A RESTful API for Notation Colleges app
 *
 * OpenAPI spec version: 1.5.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WebTadView } from '../model/webTadView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WebTadService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create webTad
     *
     * @param title
     * @param description
     * @param file
     * @param displayId
     * @param active
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling createWebTadActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling createWebTadActionIndex.');
        }

        if (displayId === null || displayId === undefined) {
            throw new Error('Required parameter displayId was null or undefined when calling createWebTadActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling createWebTadActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (displayId !== undefined) {
            formParams = formParams.append('displayId', <any>displayId) as any || formParams;
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/webTads`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete webTad
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWebTadActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteWebTadActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteWebTadActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteWebTadActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteWebTadActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/webTads/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get webTad
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<WebTadView>;
    public getWebTadActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WebTadView>>;
    public getWebTadActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WebTadView>>;
    public getWebTadActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWebTadActionIndex.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<WebTadView>('get',`${this.basePath}/api/v1/webTads/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all webTads
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionList(observe?: 'body', reportProgress?: boolean): Observable<Array<WebTadView>>;
    public getWebTadActionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebTadView>>>;
    public getWebTadActionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebTadView>>>;
    public getWebTadActionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WebTadView>>('get',`${this.basePath}/api/v1/webTads`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all active webTads
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionListActive(observe?: 'body', reportProgress?: boolean): Observable<Array<WebTadView>>;
    public getWebTadActionListActive(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebTadView>>>;
    public getWebTadActionListActive(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebTadView>>>;
    public getWebTadActionListActive(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WebTadView>>('get',`${this.basePath}/api/v1/webTads/active/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get webTads by display id
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionListByDisplay(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WebTadView>>;
    public getWebTadActionListByDisplay(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebTadView>>>;
    public getWebTadActionListByDisplay(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebTadView>>>;
    public getWebTadActionListByDisplay(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWebTadActionListByDisplay.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WebTadView>>('get',`${this.basePath}/api/v1/webTads/display/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get webTads by school id
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionListBySchool(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WebTadView>>;
    public getWebTadActionListBySchool(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebTadView>>>;
    public getWebTadActionListBySchool(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebTadView>>>;
    public getWebTadActionListBySchool(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWebTadActionListBySchool.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WebTadView>>('get',`${this.basePath}/api/v1/webTads/school/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active webTads by college url code
     *
     * @param code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWebTadActionListUrlCodeActive(code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WebTadView>>;
    public getWebTadActionListUrlCodeActive(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WebTadView>>>;
    public getWebTadActionListUrlCodeActive(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WebTadView>>>;
    public getWebTadActionListUrlCodeActive(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getWebTadActionListUrlCodeActive.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<WebTadView>>('get',`${this.basePath}/api/v1/webTads/active/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update webTad
     *
     * @param title
     * @param description
     * @param file
     * @param displayId
     * @param active
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateWebTadActionIndexForm(title: string, description: string, file: Blob, displayId: string, active: boolean, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling updateWebTadActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling updateWebTadActionIndex.');
        }

        if (displayId === null || displayId === undefined) {
            throw new Error('Required parameter displayId was null or undefined when calling updateWebTadActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling updateWebTadActionIndex.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateWebTadActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (displayId !== undefined) {
            formParams = formParams.append('displayId', <any>displayId) as any || formParams;
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/v1/webTads/${encodeURIComponent(String(id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
