/**
 * Notation Colleges API
 *  A RESTful API for Notation Colleges app
 *
 * OpenAPI spec version: 1.5.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DisplayView } from '../model/displayView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DisplayService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create display
     *
     * @param title
     * @param description
     * @param url_code
     * @param schoolId
     * @param active
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, active: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, active: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, active: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, active: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling createDisplayActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling createDisplayActionIndex.');
        }

        if (url_code === null || url_code === undefined) {
            throw new Error('Required parameter url_code was null or undefined when calling createDisplayActionIndex.');
        }

        if (schoolId === null || schoolId === undefined) {
            throw new Error('Required parameter schoolId was null or undefined when calling createDisplayActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling createDisplayActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (url_code !== undefined) {
            formParams = formParams.append('url_code', <any>url_code) as any || formParams;
        }
        if (schoolId !== undefined) {
            formParams = formParams.append('schoolId', <any>schoolId) as any || formParams;
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v1/displays`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete display
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDisplayActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDisplayActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDisplayActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDisplayActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDisplayActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/displays/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get display
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisplayActionIndex(id: string, observe?: 'body', reportProgress?: boolean): Observable<DisplayView>;
    public getDisplayActionIndex(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DisplayView>>;
    public getDisplayActionIndex(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DisplayView>>;
    public getDisplayActionIndex(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDisplayActionIndex.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DisplayView>('get',`${this.basePath}/api/v1/displays/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all displays
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisplayActionList(observe?: 'body', reportProgress?: boolean): Observable<Array<DisplayView>>;
    public getDisplayActionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DisplayView>>>;
    public getDisplayActionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DisplayView>>>;
    public getDisplayActionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DisplayView>>('get',`${this.basePath}/api/v1/displays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all active displays
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisplayActionListActive(observe?: 'body', reportProgress?: boolean): Observable<Array<DisplayView>>;
    public getDisplayActionListActive(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DisplayView>>>;
    public getDisplayActionListActive(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DisplayView>>>;
    public getDisplayActionListActive(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DisplayView>>('get',`${this.basePath}/api/v1/displays/active/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get displays by school id
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisplayActionListBySchool(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DisplayView>>;
    public getDisplayActionListBySchool(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DisplayView>>>;
    public getDisplayActionListBySchool(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DisplayView>>>;
    public getDisplayActionListBySchool(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDisplayActionListBySchool.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DisplayView>>('get',`${this.basePath}/api/v1/displays/school/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active displays by college url code
     *
     * @param code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDisplayActionListUrlCodeActive(code: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DisplayView>>;
    public getDisplayActionListUrlCodeActive(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DisplayView>>>;
    public getDisplayActionListUrlCodeActive(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DisplayView>>>;
    public getDisplayActionListUrlCodeActive(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling getDisplayActionListUrlCodeActive.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DisplayView>>('get',`${this.basePath}/api/v1/displays/active/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update display
     *
     * @param title
     * @param description
     * @param url_code
     * @param schoolId
     * @param webtadIds
     * @param active
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, webtadIds: Array<string>, active: boolean, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, webtadIds: Array<string>, active: boolean, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, webtadIds: Array<string>, active: boolean, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDisplayActionIndexForm(title: string, description: string, url_code: string, schoolId: string, webtadIds: Array<string>, active: boolean, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling updateDisplayActionIndex.');
        }

        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling updateDisplayActionIndex.');
        }

        if (url_code === null || url_code === undefined) {
            throw new Error('Required parameter url_code was null or undefined when calling updateDisplayActionIndex.');
        }

        if (schoolId === null || schoolId === undefined) {
            throw new Error('Required parameter schoolId was null or undefined when calling updateDisplayActionIndex.');
        }

        if (webtadIds === null || webtadIds === undefined) {
            throw new Error('Required parameter webtadIds was null or undefined when calling updateDisplayActionIndex.');
        }

        if (active === null || active === undefined) {
            throw new Error('Required parameter active was null or undefined when calling updateDisplayActionIndex.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateDisplayActionIndex.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (title !== undefined) {
            formParams = formParams.append('title', <any>title) as any || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('description', <any>description) as any || formParams;
        }
        if (url_code !== undefined) {
            formParams = formParams.append('url_code', <any>url_code) as any || formParams;
        }
        if (schoolId !== undefined) {
            formParams = formParams.append('schoolId', <any>schoolId) as any || formParams;
        }
        if (webtadIds) {
            webtadIds.forEach((element) => {
                formParams = formParams.append('webtadIds', <any>element) as any || formParams;
            })
        }
        if (active !== undefined) {
            formParams = formParams.append('active', <any>active) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/v1/displays/${encodeURIComponent(String(id))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
