/**
 * Notation Colleges API
 *  A RESTful API for Notation Colleges app
 *
 * OpenAPI spec version: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SchoolView { 
    id: string;
    title: string;
    description: string;
    code_insee: number;
    top_banner_background_color: string;
    bottom_banner_background_color: string;
    top_banner_text_inverted_color: boolean;
    bottom_banner_text_inverted_color: boolean;
    active: boolean;
}