export * from './categoryDTO';
export * from './categoryToCriterionDTO';
export * from './categoryView';
export * from './credentialDTO';
export * from './credentialView';
export * from './criterion';
export * from './criterionDTO';
export * from './criterionView';
export * from './display';
export * from './displayAndWebtadsDTO';
export * from './displayDTO';
export * from './displayView';
export * from './gradeDTO';
export * from './gradeView';
export * from './jury';
export * from './juryDTO';
export * from './juryView';
export * from './loginDTO';
export * from './project';
export * from './projectDTO';
export * from './projectUploadDTO';
export * from './projectView';
export * from './rightView';
export * from './school';
export * from './schoolDTO';
export * from './schoolView';
export * from './sheet';
export * from './sheetDTO';
export * from './sheetView';
export * from './tag';
export * from './tagDTO';
export * from './tagView';
export * from './template';
export * from './templateDTO';
export * from './templateToCategoryDTO';
export * from './templateView';
export * from './templateWithSheetsView';
export * from './user';
export * from './userDTO';
export * from './userEditDTO';
export * from './userToRightDTO';
export * from './userView';
export * from './webTadDTO';
export * from './webTadView';
