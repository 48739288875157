import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgxEditorModule } from 'ngx-editor';
import { FormsModule } from '@angular/forms';

import {
  NbChatModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbDialogModule,
  NbMenuModule as NbMenuModule2,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { ApiModule } from './_generated';
import { AuthInterceptor } from './_interceptors/auth.interceptor';
import { ErrorService } from './_services/error.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StoreModule } from '@ngrx/store';
import { userReducer } from './_store/reducers/principal.reducer.s';
import { contextReducer } from './_store/reducers/context.reducer.s';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './_services/auth-guard.service';
import { InternUserService } from './_services/intern-user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbMenuModule } from './@theme/menu/menu.module';
import { NgxDnDModule } from '@swimlane/ngx-dnd';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    ApiModule,
    NbDialogModule.forRoot(),
    NgxDnDModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot({
      user: userReducer,
      context: contextReducer,
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbMenuModule.forRoot(),
    NbMenuModule2.forRoot(),
    FormsModule,
    NgxEditorModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    JwtHelperService,
    InternUserService,
    ErrorService,
    AuthGuard,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
