/**
 * Notation Colleges API
 *  A RESTful API for Notation Colleges app
 *
 * OpenAPI spec version: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ProjectDTO { 
    title: string;
    description: string;
    city: string;
    facility: string;
    theme: string;
    space: string;
    budget: number;
    rank: number;
    juryId: string;
    active: boolean;
}